<template>
  <div class="empty">
    <img style="margin-top: 118px" src="../assets/empty.png" alt=""/>
    <div
        style="margin-top:23px;width: 176px;height: 16px;font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;line-height: 24px;">
      抱歉，没有找到相关数据
    </div>
    <div v-if="word"
         style="margin-top: 120px;height: 16px;font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;line-height: 24px;">
      尝试与“<span style="color: rgba(245, 108, 108, 1)">{{ word }}</span>”相关的文章：
    </div>
    <div  v-if="keyList&&keyList[0]&&keyList[0].values&&keyList[0].values.length>0"
        style="margin-top: 28px;display: flex;font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #063F95;">
      <div v-for="(item,index) in (keyList[0].values||[])"
           style="cursor: pointer;margin:0 10px;padding: 0 10px;height: 28px;background: #F5F5F5;display: flex;align-items: center;">
        <span @click="onClickSearch(item.name)">{{ item.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Empty",
  props: ["keyList", "word"],
  methods: {
    onClickSearch(word) {
      this.$router.push({
        name: "Periodical",
        query: {
          collection: "Periodical",
          select: "所有领域",
          word: '关键词:\"' + word + '\"',
          currentPage: 1,
          pageSize: 10,
          filter: []
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.empty {
  width: 100%;
  height: 800px;
  background: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
  flex-flow: column;
}
</style>